import './src/scss/theme.scss'
import('../../src/js/app')
const Rellax = require('rellax')
require('owl.carousel')

$(document).ready(function () {
  const rellax = new Rellax('.rellax')
})

$(document).ready(function () {
  $('.suggestion-slider .owl-carousel').owlCarousel({
    margin: 30,
    autoplay: true,
    autoplaySpeed: 1000,
    autoplayTimeout: 10000,
    responsive: {
      0: {
        loop: true,
        nav: true,
        items: 1,
      }
    }
  })
})
